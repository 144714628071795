import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./Auth.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { forgotPasswordStart } from "../store/actions/UserAction";

const ForgotPassword = (props) => {
  const t = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("required")),
  });

  const handleSubmit = (values) => {
    props.dispatch(forgotPasswordStart(values));
  };

  return (
    <>
      <div className="login-sec reset-password-sec">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xl={5} md={12} lg={6}>
              <div className="login-form">
                <h3>{t("forgot_password")}</h3>
                <p>{t("password_reset_mail")}</p>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  <FORM>
                    {/* <div className="required-content">
                      <p>{t("required_fields")}*</p>
                    </div> */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("email")}<span>*</span></Form.Label>
                      <Field
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder={t("email_placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger"
                      />
                    </Form.Group>

                    <div className="login-btn default-btn-sec reset-btn">
                      <Button
                        className="default-btn"
                        type="submit"
                        disabled={props.sendForgotPassword.buttonDisable}
                      >
                        {props.sendForgotPassword.loadingButtonContent
                          ? props.sendForgotPassword.loadingButtonContent
                          : t("submit")}
                      </Button>
                    </div>
                  </FORM>
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  sendForgotPassword: state.users.forgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ForgotPassword));
