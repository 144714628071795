import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeroStaticProductIndex from "./HeroStaticProductIndex";
import HomeBannerIndex from "./HomeBannerIndex";
import "./Landing.css";
import NewArrivalIndex from "./NewArrivalIndex";
import TopSellingProductIndex from "../Product/TopSellingProductIndex";
import LandingAboutIndex from "./LandingAboutIndex";
import CustomerReviewIndex from "./CustomerReviewIndex";
import FAQIndex from "./FAQIndex";
import { connect } from "react-redux";
import { fetchProductListStart } from "../store/actions/ProductAction";
import {
  guestCartList,
  saveCartDetailsStart,
} from "../store/actions/CartsAction";
import { t } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import ContactUs from "../Contact/ContactUs";

const LandingPageIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchProductListStart({
        order_by: "newest",
        skip: 0,
        take: 8,
      })
    );
  }, []);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      if (props.guestCartList.length > 0) {
        props.dispatch(
          saveCartDetailsStart({
            carts: JSON.stringify(props.guestCartList),
          })
        );
        props.dispatch(guestCartList([]));
        localStorage.removeItem("carts");
      }
    }
  }, [props.profile]);

  return (
    <>
      <div className="landing-page-sec">
        <HomeBannerIndex />
        {/* <HeroStaticProductIndex /> */}
        {props.productList.loading ? (
          <div className="d-flex justify-content-between">
            <Skeleton height={650} width={650} />
            <Skeleton height={650} width={850} />
          </div>
        ) : props.productList.data.products &&
          props.productList.data.products.length > 0 ? (
          <>
            <div className="top-selling-product-page">
              <Container>
                <Row className="justify-content-center">
                  <Col md={7}>
                    <div className="section-title-sec">
                      <h2 className="title-light">{t("our_products")}</h2>
                      <h1 className="title-bold">
                        <span>{t("products_text")}</span>
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <TopSellingProductIndex
                      products={props.productList.data.products}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        ) : null}
        <LandingAboutIndex />
        {props.productList.loading ? (
          <div className="d-flex justify-content-between">
            <Skeleton height={650} width={650} />
            <Skeleton height={650} width={850} />
          </div>
        ) : props.productList.data.products &&
          props.productList.data.products.length > 0 ? (
          <>
            <NewArrivalIndex products={props.productList.data.products} />
          </>
        ) : null}
        
        <FAQIndex />
        <ContactUs />
        <CustomerReviewIndex />

      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  productList: state.product.productList,
  profile: state.users.profile,
  guestCartList: state.carts.guestCartList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LandingPageIndex);
