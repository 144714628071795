import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";
import { connect } from "react-redux";
import Slider from "react-slick";
import { withTranslation, useTranslation } from "react-multi-lang";
import { fetchBannersListStart } from "../store/actions/HomePageAction";
import BannerListLoader from "../Helper/BannerListLoader";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const HomeBannerIndex = (props) => {
  const t = useTranslation();

  const { bannerList } = props;

  useEffect(() => {
    props.dispatch(fetchBannersListStart());
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
      <div className="home-banner-sec">
        {bannerList.loading ? (
          <BannerListLoader />
        ) : (
          <Container>
            {bannerList.data.banners && bannerList.data.banners.length > 0 ? (
              <div className="home-banner-content">
                  {bannerList.data.banners.slice(0,1).map((banner, index) => (
                          <div className="home-banner-info">
                            {/* <h5>{t("cosmetics")}</h5> */}
                            <h1>{banner.title}</h1>
                            <p>{banner.description}</p>
                            <div className="default-btn-sec">
                              <Link
                                to="/shop"
                                className="default-btn"
                              >
                                {t("shop_now")}
                              </Link>
                            </div>
                          </div>
                  ))}
                  <div className="banner-brand-text">
                    Misabloom
                  </div>
              </div>
            ) : null}
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bannerList: state.homepage.bannerList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HomeBannerIndex));
