import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Auth.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { resetPasswordStart } from "../store/actions/UserAction";

const ResetPassword = (props) => {
  const [skipRender, setSkipRender] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const t = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("required"))
      .test(
        "len",
        "password length should be minimum 6",
        (val) => val && val.length >= 6
      ),
    password_confirmation: Yup.string()
      .required(t("required"))
      .when(t("password"), {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref(t("password"))],
          t("passwords_need_to_be_same")
        ),
      }),
  });

  const reset = (values) => {
    props.dispatch(
      resetPasswordStart({
        ...values,
        reset_token: params.token,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.resetPassword.loading &&
      props.resetPassword.data.success
    ) {
      navigate("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);

  return (
    <>
      <div className="login-sec reset-password-sec">
        <Container>
          <div className="login-title">
            {/* <h4>{t("already_registered")}</h4> */}
          </div>
          <Row className="align-items-center justify-content-center">
           <Col xl={5} md={12} lg={6}>
              <div className="login-form">
                <h3>{t("reset_password")}</h3>
                <p>{t("password_reset_mail")}</p>
                <Formik
                  initialValues={{
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => reset(values)}
                >
                  <FORM>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("new_password")} <span>*</span></Form.Label>
                      <Field
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder={t("new_password_placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("confirm_password")} <span>*</span></Form.Label>
                      <Field
                        type="password"
                        className="form-control"
                        name="password_confirmation"
                        placeholder={t("confirm_password_placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="text-danger"
                      />
                    </Form.Group>
                    <div className="login-btn default-btn-sec reset-btn">
                      <Button className="default-btn" type="submit">
                        {t("reset_password")}
                      </Button>
                    </div>
                  </FORM>
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ResetPassword));
