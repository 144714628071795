import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { changePasswordStart } from "../store/actions/UserAction";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const ChangePassword = (props) => {
  const changePasswordForm = useRef();
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (
      !skipRender &&
      !props.changePassword.loading &&
      Object.keys(props.changePassword.data).length > 0
    ) {
      // navigate("/logout");
      changePasswordForm.current.resetForm();
    }
    setSkipRender(false);
  }, [props.changePassword]);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")).required(t("required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("should_not_be_old_password")
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("passwords_need_to_be_same")
        ),
      }),
  });

  const validateNewPassword = (value) => {
    if (value) {
      if (value !== password) {
        let formatted = value.replaceAll(/\s+/g, "");
        if (formatted !== value) {
          return "Password should not contain any space";
        } else {
          setPassword(value);
          return "";
        }
      }
    }
  };
  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("change_password")}</h4>
                  {/* <p>
                    {t("welcome")} {props.profile.data.name}
                  </p> */}
                </div>
                {props.profile.loading ?
                  <>
                    <Skeleton height={60} className='mb-4' />
                    <Skeleton height={60} width={450} className='mb-2' />
                    <Skeleton height={50} width={110} />
                  </>
                  :
                  <div className="login-form">
                    <Formik
                      innerRef={changePasswordForm}
                      initialValues={{
                        old_password: "",
                        password: "",
                        password_confirmation: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      <FORM>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("old_password")} <span>*</span></Form.Label>
                              <div className="input-group">
                                <Field
                                  type="password"
                                  name="old_password"
                                  placeholder={t("old_password_placeholder")}
                                  className="form-control"
                                />
                              </div>
                              <ErrorMessage
                                component={"div"}
                                name="old_password"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>{t("new_password")} <span>*</span></Form.Label>
                              <div className="input-group">
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder={t("new_password_placeholder")}
                                  className="form-control"
                                  validate={validateNewPassword}


                                />
                              </div>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("confirm_password")} <span>*</span></Form.Label>
                              <div className="input-group">
                                <Field
                                  type="password"
                                  name="password_confirmation"
                                  placeholder={t("confirm_password_placeholder")}
                                  className="form-control"
                                 

                                />
                              </div>
                              <ErrorMessage
                                component={"div"}
                                name="password_confirmation"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </Col>
                          <div className="login-btn default-btn-sec">
                            <Button className="default-btn" type="submit"
                              disabled={props.changePassword.buttonDisable}
                            >
                              {props.changePassword.loadingButtonContent
                                ? props.changePassword.loadingButtonContent
                                : (t("save"))}
                            </Button>
                          </div>
                        </Row>
                      </FORM>
                    </Formik>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ChangePassword));
