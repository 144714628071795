import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { customerReviewsListStart } from "../store/actions/HomePageAction";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { withTranslation, useTranslation } from "react-multi-lang";

const CustomerReviewIndex = (props) => {
  const t = useTranslation();
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };

  useEffect(() => {
    props.dispatch(customerReviewsListStart({ order_by: "newest" }));
  }, []);

  return (
    <>
      {props.customerReviewsList.loading ? (
        <div className="d-flex justify-content-around">
          <Skeleton height={350} width={600} />
          <Skeleton height={350} width={600} />
        </div>
      ) : props.customerReviewsList.data.reviews &&
        props.customerReviewsList.data.reviews.length > 0 ? (
        <div className="customer-review-page">
          <Container>
            <Row>
              <Col md={12}>
                <div className="section-title-sec">
                  <h5 className="title-light">{t("the_products")}</h5>
                  <h2 className="title-bold">
                    <span>{t("customer_review")}</span>
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="customer-review-sec">
                  <Container>
                    <Row>
                      <Col md={12}>
                        <Slider {...settings}>
                          {props.customerReviewsList.data.reviews.map((reviews, i) => (
                            <div>
                              <div className="new-customer-review-card">
                                <div className="customer-rating-box">
                                  <div className="customer-product">
                                    <div className="customer-product-img">
                                      <Image
                                        src={reviews.product.file}
                                        type="image/png"
                                        alt={reviews.product.name}
                                      />
                                    </div>
                                    <div className="customer-product-content">
                                      <h4>{reviews.product.name}</h4>
                                    </div>
                                  </div>
                                  <div className="customer-review-content">
                                    <div className="customer-review-user-info">
                                      <div className="customer-review-user-img-sec">
                                        <Image
                                          className="customer-review-user-img"
                                          src={reviews.user.picture}
                                          type="image/png"
                                        />
                                      </div>
                                      <div>
                                        <h4> {reviews.user.name}</h4>
                                        <ReactStars
                                          count={5}
                                          value={reviews.star}
                                          edit={false}
                                          // onChange={ratingChanged}
                                          size={24}
                                          isHalf={true}
                                          emptyIcon={<i class="far fa-star"></i>}
                                          halfIcon={
                                            <i class="fas fa-star-half-alt"></i>
                                          }
                                          fullIcon={<i class="fas fa-star"></i>}
                                          activeColor="#ffd700"
                                        />
                                      </div>
                                    </div>

                                    <div className="customer-rating">
                                      <p>{reviews.review}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>

                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        null
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  customerReviewsList: state.homepage.customerReviewsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CustomerReviewIndex));
