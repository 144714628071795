import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import { contactSubmitStart } from "../store/actions/HomePageAction";
import { Link } from "react-router-dom";
import "./Contact.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import FAQIndex from "../landingPage/FAQIndex";

const ContactUs = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const contactRef = useRef();

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
    query: Yup.string().required(t("query_required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("must_contain_9_characters")
    ),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.contactSubmit.loading &&
      Object.keys(props.contactSubmit.data).length > 0
    ) {
      contactRef.current.resetForm();
    }
    setSkipRender(false);
  }, [props.contactSubmit]);

  const handleSubmit = (values) => {
    props.dispatch(contactSubmitStart(values));
  };

  return (
    <>
      <div className="contact-sec sm-padding">
        <Container>
          <div className="contact-form contact-form-padding">
            <Row className="justify-content-center" >
              <Col md={6}>
                <div className="contact-form-details">
                  <div className="login-form">
                    <h3>{t("send_us_a_message")}</h3>
                    <p>{t("fill_the_form")}</p>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        query: "",
                        mobile: "",
                      }}
                      validationSchema={contactSchema}
                      onSubmit={(values) => handleSubmit(values)}
                      enableReinitialize={true}
                      innerRef={contactRef}
                    >
                      <FORM>
                        <Row>
                          <Col lg={6}>
                          <Form.Group className="mb-3">
                          <Field
                            type="text"
                            name="name"
                            placeholder={t("name_placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                          </Col>
                          <Col lg={6}>
                          <Form.Group className="mb-3">
                          <Field
                            type="number"
                            name="mobile"
                            placeholder={t("mobile_placeholder")}
                            className="form-control"
                            min="0"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                          </Col>
                          <Col lg={12}>
                          <Form.Group className="mb-3">
                          <Field
                            type="email"
                            name="email"
                            placeholder={t("email_placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                          </Col>
                          <Col lg={12}>
                          <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Field
                            as="textarea"
                            name="query"
                            placeholder={t("query_placeholder")}
                            className="form-control"
                            Row={4}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="query"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                          </Col>
                        </Row>
                        
                        
                        
                        
                        <div className="login-btn default-btn-sec">
                          <Button className="default-btn" type="submit">
                            {t("send_message")}
                          </Button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* <div className="location-map-sec">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0500684385433!2d-122.40763478500494!3d37.78886637975676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858089179bab83%3A0xa22f6f7dfec336da!2s210%20Post%20St%2C%20San%20Francisco%2C%20CA%2094108%2C%20USA!5e0!3m2!1sen!2sin!4v1659968709508!5m2!1sen!2sin"
            width="100%"
            height="250"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div> */}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  contactSubmit: state.homepage.contactSubmit,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ContactUs));
