import React, { useState } from "react";
import "./Profile.css";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { updateUserDetailsStart } from "../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const ProfileIndex = (props) => {
  const [profileImg, setProfileImg] = useState(null);

  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);

  const t = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("required")),
    name: Yup.string().required(t("required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("must_contain_9_characters")
    ),
  });

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImg) {
      newValues = {
        ...values,
        picture: profileImg,
      };
    }
    props.dispatch(updateUserDetailsStart(newValues));
  };

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    setProfileImg(e.target.files[0]);
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="mobile-sidebar-sec">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec mobile-right-profile-sec">
                <div className="profile-details-title">
                  <h4>{t("personal_details")}</h4>
                  <p>
                    {t("welcome")} {props.profile.data.name}
                  </p>
                </div>
                {props.profile.loading ?
                  <>
                    <Skeleton
                      height={80}
                      width={80}
                      circle={true}
                      className='mb-4'
                    />

                    <Skeleton
                      height={200}
                    />
                  </>
                  :
                  Object.keys(props.profile.data).length > 0 ? (
                    <div className="login-form">
                      <Formik
                        initialValues={{
                          name: props.profile.data.name,
                          mobile: props.profile.data.mobile,
                          email: props.profile.data.email,
                          // username: props.profile.data.username,
                        }}
                        onSubmit={(values) => handleSubmit(values)}
                        validationSchema={validationSchema}
                      >
                        <FORM>
                          <div className="profile-img">

                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>
                                <CustomLazyLoad
                                  src={imgPreviewAdd
                                    ? imgPreviewAdd
                                    : props.profile.data.picture}
                                  placeholderSrc={window.location.origin + "/images/placeholder.jpeg"}
                                  classes="profile-image"
                                />
                                <div className="input-edit-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/edit.svg"
                                    }
                                    type="image/png"
                                  />
                                </div>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="image/*"
                                className="image-input"
                                onChange={(e) => imgPreview(e)}
                              />
                            </Form.Group>
                          </div>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>{t("name")} <span>*</span></Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder={t("name_placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="name"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>{t("email")} <span>*</span></Form.Label>
                                <Field
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder={t("email_placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("username")}</Form.Label>
                              <Field
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder={t("username_placeholder")}
                                disabled={true}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="username"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col> */}
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>{t("mobile_no")} <span>*</span></Form.Label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  name="mobile"
                                  placeholder={t("mobile_placeholder")}
                                /> 
                                <ErrorMessage
                                  component={"div"}
                                  name="mobile"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <div className="login-btn default-btn-sec">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={props.profileInputData.buttonDisable}
                              >
                                {props.profileInputData.loadingButtonContent
                                  ? props.profileInputData.loadingButtonContent
                                  : t("save")}
                              </Button>
                            </div>
                          </Row>
                        </FORM>
                      </Formik>
                    </div>
                  ) : (
                    ""
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProfileIndex));
