import React, { useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation, withTranslation } from "react-multi-lang";
import { fetchCategoriesListStart } from "../../store/actions/HomePageAction";
import { connect } from "react-redux";

const FooterIndex = (props) => {
  const t = useTranslation();


  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  return (
    <>
      <footer className="footer-sec">
        <div className="footer-header-sec pb-0">
          <Container>
            <div className="footer-header-box">
              {/* <div className="footer-header-card">
                <div className="footer-header-icon-sec">
                  <Image
                    className="footer-header-icon"
                    src={window.location.origin + "/images/wallet.png"}
                    type="image/png"
                  />
                </div>
                <div className="footer-header-info">
                  <h4>{t("global_payments_options")}</h4>
                </div>
              </div> */}
              <div className="footer-header-card">
                <div className="footer-header-icon-sec">
                  <Image
                    className="footer-header-icon"
                    src={
                      window.location.origin + "/images/24-hours-support.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="footer-header-info">
                  <h4>24/7 {t("support_staff")}</h4>
                </div>
              </div>
              <div className="footer-header-card">
                <div className="footer-header-icon-sec">
                  <Image
                    className="footer-header-icon"
                    src={window.location.origin + "/images/shield.png"}
                    type="image/png"
                  />
                </div>
                <div className="footer-header-info">
                  <h4>100% {t("safe_products")}</h4>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="space-liner"></div>
        <div className="main-footer-sec">
          <Container>
            <Row className="justify-content-center">
              <Col md={4} xl={3} lg={3} className="resp-mrg-btm-md">
                <div className="footer-company-widget">
                  <div className="footer-log-sec">
                    <Image
                      className="footer-logo"
                      src={window.location.origin + "/images/blooms/logo-white.svg"}
                      type="image/png"
                      alt={configuration.get("configData.site_name")}
                    />
                  </div>
                  <div className="footer-company-info">
                    <p>{configuration.get("configData.footer_description")}</p>
                  </div>
                </div>
              </Col>
              <Col md={2} xl={2} lg={2} xs={6}>
                <div className="footer-widget pl-3">
                  <h4 className="text-uppercase">Company</h4>
                  <ul className="list-unstyled footer-link">
                    <li>
                      <Link to="/page/track-your-order">Track Your Order</Link>
                    </li>
                    <li>
                      <Link to="/page/shipping-guidelines">Shipping guidelines</Link>
                    </li>
                    
                    <li>
                      <Link to="/page/accessibility-statement">Accessibility Statement</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={2} xl={2} lg={2} xs={6}>
                <div className="footer-widget">
                  <h4>Policies</h4>
                  <ul className="list-unstyled footer-link">
                    <li>
                      <Link to="/page/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/page/refund-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/page/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    {/* <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">FAQ's</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/page/news">News</Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              {props.categoriesList.loading ? 
              null 
              :
              props.categoriesList.data.categories &&
              props.categoriesList.data.categories.length > 0 ?
              <Col md={2} xl={2} lg={2} xs={6}>
              
                  <div className="footer-widget">
                    <h4>Category</h4>
                    <ul className="list-unstyled footer-link">
                      {props.categoriesList.data.categories.map(
                          (category, i) => (
                            <li>
                              <Link to=
                              {`/shop?category=${category.category_unique_id}`}>{category.name}</Link>
                            </li>
                          ))}
                    </ul>
                  </div>
              </Col> 
              : null
                }

              <Col md={2} xl={3} lg={3} xs={12}>
                <div className="footer-widget">
                  {/* <h4>{t("socials")}</h4> */}
                  <h4 className="text-uppercase">Contact Us</h4>
                  <div class="new-contact-address new-contact-landing-address">
                    {/* <h4>{t("india")}</h4> */}
                    {configuration.get("configData.contact_address") ? (
                      <div class="new-contact-link">
                        <div class="contact-address-img">
                          <Image
                            src={
                              window.location.origin + "/images/location.svg"
                            }
                            type="image/png"
                          />
                        </div>
                        <p>{configuration.get("configData.contact_address")}</p>
                      </div>
                    ) : null}
                    {configuration.get("configData.contact_mobile") ? (
                      <div class="new-contact-link">
                       
                          <div class="contact-address-img">
                            <Image
                              src={window.location.origin + "/images/call.svg"}
                              type="image/png"
                            />
                          </div>
                          <a
                          href={`tel:${configuration.get(
                            "configData.contact_mobile"
                          )}`}
                        >
                            {configuration.get("configData.contact_mobile")}
                        </a>
                      </div>
                    ) : null}
                    {configuration.get("configData.contact_email") ? (
                      <div class="new-contact-link">
                        
                          <div class="contact-address-img">
                            <Image
                              src={window.location.origin + "/images/mail.svg"}
                              type="image/png"
                            />
                          </div>
                          <a
                          href={`mailto:${configuration.get(
                            "configData.contact_email"
                          )}`}
                        >{configuration.get("configData.contact_email")}
                        </a>
                      </div>
                    ) : null}
                    <div className="footer-contact-btn default-btn-sec mt-4">
                      <Link to="/contact-us" className="default-btn">
                        Talk to us
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="copy-rights-sec">
              <Row>
                <Col md={12}>
                  <ul className="list-unstyled social-link">
                    {configuration.get("configData.instagram_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.instagram_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/instagram-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.twitter_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.twitter_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/twitter-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.linkedin_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.linkedin_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/linkedin-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.facebook_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.facebook_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/facebook-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.youtube_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.youtube_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/youtube-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}
                  </ul>
                  <div className="space-liner"></div>
                  <div className="copyright-sub-sec">
                    <h4>2024 © Copyrights Misbloom, Inc.</h4>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* <div className="sub-footer-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="sub-footer-box">
                  <div className="sub-footer-info">
                    <h3>{t("subscribe_to_our_newsletter_wedontspam")}</h3>
                    <ul className="list-unstyled sub-footer-link">
                      <li>
                        <Link to="#">{t("product_recommendation")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("industry_updates")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("discounts_offers")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("learn_about_Fashion")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("product_adoption_ideas")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="sub-footer-btn-sec">
                    <Button className="subscribe-btn">{t("subscribe")}</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </footer>
    </>
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.homepage.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(FooterIndex);





